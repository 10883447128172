@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rethink+Sans:wght@700&display=swap');
p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

a {
   text-decoration: none;
   color:#490d5e,
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.styledComponentTitle{
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    padding: 5px;
}
.styledComponentSubTitle{
    font-family: "Lato", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 12px;
    opacity:0.7
}